import React, {useState, useEffect, useRef, useReducer, useMemo} from 'react';
import AppProvider from 'library/AppProvider';
import storeReducer from 'library/storeReducer';
import request from 'library/request';
import parseRoutes from 'library/parseRoutes';
import load from 'library/load';
import {mainRoutes, route, group} from 'library/router';
import {ThemeProvider} from 'styled-components';
import 'library/styles/reset.css';
import 'library/fonts/font-awesome/css/all.min.css';
import config from 'config';
import {merge, sort} from 'library/helpers';

const Loading = config.components.loading;
const Preloading = config.components.preloading;
const Alert = config.components.alert;
const Confirm = config.components.confirm;

export default function App(props){

    useEffect(() => {

        if(engine.current.reset === true){

            // Reset only once

            engine.current.reset = false;

            // Load required files before starting initializing

            const theme = engine.current.side !== undefined ? engine.current.side : config.defaultTheme;

            engine.current.imports.set(engine.current.lang + '@lang', {file: engine.current.lang + '.json', type: 'lang'});
            engine.current.imports.set(theme + '@theme', {file: theme + '.js', type: 'theme'});

            const modules = [];

            engine.current.imports.forEach((importable, k) => {

                if(importable.type === 'theme'){

                    modules.push(import(/* webpackChunkName: "[request]" */ 'themes/' + importable.file).then(

                        module => {

                            importable.module = module.default; // Add imported module
                            engine.current.imports.set(k, importable);
                        },

                        error => {

                            throw new Error(error);
                        }
                    ));
                }

                else if(importable.type === 'lang'){

                    modules.push(import(/* webpackChunkName: "[request]" */ 'lang/' + importable.file).then(

                        module => {

                            importable.module = module.default; // Add imported module
                            engine.current.imports.set(k, importable);
                        },

                        error => {

                            throw new Error(error);
                        }
                    ));
                }
            });

            Promise.all(modules).then(()  => {

                engine.current.setState({reset: false});
            });
        }

        else if(engine.current.init === true){
            
            // Init only once

            engine.current.init = false;

            const promises = [];

            // If access token is set check privileges

            if(localStorage.getItem('access_token') !== null){

                const  options = config.endpoints.privileges;

                promises.push(request(options));
            }
            
            Promise.all(promises).then(

                result => {

                    if(result[0] !== undefined && result[0].data !== undefined){

                        // Logout if we do not get privileges

                        if(result[0].code === 400 && result[0].data === false){

                            engine.current.logout();
                        }

                        else {

                            const groups = result[0].data.groups === undefined ? result[0].data : result[0].data.groups;

                            engine.current.setState({groups: groups});
                        }
                    }
                },

                error => {

                    // Error with loading privileges

                    if(process.env.NODE_ENV === 'development'){

                        console.error(error);
                    }
                }

            ).finally(() => {

                // Initial loading

                load(engine, app.path).then(

                    () => {

                        engine.current.setState({initializing: false});

                        if(engine.current.isForbidden(app.path) === true){

                            engine.current.openForbiddenModal();
                        }
                    },

                    error => {

                        engine.current.setState({initializing: false, loadingError: true});
                        engine.current.handleError(error);
                    }
                );
            });

             // Listen history API state change

            window.onpopstate = () => {

                engine.current.setState({loading: true});

                const path = window.location.pathname;

                // Load on pop state

                load(engine, path).then(

                    () => {

                        if(engine.current.isForbidden(path) === true){
                        
                            engine.current.openAlertModal(getLang('system').messages.forbidden, () => {

                                engine.current.openForbiddenModal();
                            });

                            engine.current.setState({loading: false});
                        }

                        else {

                            engine.current.setState({path: path, loading: false});
                        }
                    },

                    error => {

                        engine.current.handleError(error);
                    }
                );
            }
        }
    });

    const initialState = {

        path: window.location.pathname,
        reset: true,
        initializing: true,
        loading: false,
        groups: [], // Current users access rights
        loadingError: false,
        sharedState: new Map()
    };

    const [app, setAppState] = useState(initialState);
    const [stores, dispatchStores] = useReducer(storeReducer, {});
    const [alertModal, setAlertModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [lang, setLangState] = useState(localStorage.getItem('lang') === null ? 'fi' : localStorage.getItem('lang'));

    function setLang(lang){

        function set(){

            engine.current.lang = lang;
            setLangState(lang);
            localStorage.setItem('lang', lang);
        }

        const translations = [

            {importable: lang + '@lang', file: lang + '.json'},
            {importable: lang + '@' + engine.current.side, file: engine.current.side + '/' + lang + '.json'}
        ];

        const promises = [];

        translations.forEach(translation => {

            // Language is not yet loaded

            if(engine.current.imports.get(translation.importable) === undefined){

                if(engine.current.state.loading !== true){

                    engine.current.setState({loading: true});
                }

                promises.push(import(/* webpackChunkName: "[request]" */ 'lang/' + translation.file).then(

                    module => {

                        engine.current.imports.set(translation.importable, {file: translation.from, module: module.default});
                    },

                    error => {

                        return Promise.reject(error);
                    }
                ));
            }
        });

        Promise.all(promises).then(

            () => {

                engine.current.setState({loading: false});
                set();
            },

            rejected => {

                throw new Error(rejected);
            }
        );
    }

    const engine = useRef({

        lang: lang,
        imports: new Map(), // Module cache
        cache: new Map(), // Dependencies cache
        routes: mainRoutes,
        alertModalCallback: false,
        confirmModalCallback: false,
        state: initialState, // Copy of a latest state
        prev: {state: {}},
        forbidden: [],
        init: true,
        reset: true,
        side: undefined,

        // Use ref to merge state always with the latest state even from asynchronous callbacks or events

        setState(values){

            const state = {...engine.current.state, ...values};

            engine.current.state = state;

            setAppState(prev => {

                engine.current.prev.state = prev;

                return state;
            });
        },

        // Preload path before changing it

        setRoute: (path, options) => {

            if(options === undefined){

                options = {};
            }

            engine.current.setState({loading: true, loadingError: false}); // Reset possible previous loading error

            // Load by request

            load(engine, path, options).then(

                () => {

                    // Set props to pass

                    if(options.data !== undefined){

                        engine.current.props = {

                            path: path,
                            data: options.data
                        };
                    }

                    else {
                        
                        delete engine.current.props;
                    }

                    if(engine.current.isForbidden(path) === true){

                        engine.current.openForbiddenModal();

                        engine.current.setState({loading: false});
                    }

                    else {

                        // When path is changed using browsers back or forward button

                        if(path !== window.location.pathname){

                            window.history.pushState(path, document.title, path);
                        }

                        engine.current.setState({path: path, loading: false});
                    }

                    engine.current.forbidden = [];
                },

                error => {

                    engine.current.setState({loading: false});
                    engine.current.handleError(error, () => engine.current.setRoute(path));
                }
            );
        },

        // Handle stores

        setStores: action => {

            dispatchStores(action);
        },

        // Handle forbidden

        setForbidden: path => {

            if(engine.current.forbidden.indexOf(path) === -1){

                engine.current.forbidden.push(path);
            }
        },

        isForbidden: path => {

            if(engine.current.forbidden.indexOf(path) === -1){

                return false;
            }

            return true;
        },

        // Handle error

        handleError: (error, callback) => {

            if(error.notFound === true){

                engine.current.openAlertModal(getLang('system').messages.not_found, () => engine.current.redirect());
            }

            else if(error.forbidden === true){

                engine.current.openForbiddenModal();
            }

            if(error.network === true){

                const message = getLang('system').messages.network_error;
                const button =  getLang('system').confirm_modal.try_again;

                if(callback === undefined){
                    
                    engine.current.openConfirmModal(message, () => location.reload(), button);
                }

                else {

                    engine.current.openConfirmModal(message, () => callback(), button);
                }
            }
        },

        // Handle alert modal

        openAlertModal: (value, callback) => {

            setAlertModal(value);

            if(callback !== undefined){

                engine.current.alertModalCallback = callback;
            }
        },

        closeAlertModal: () => {

            setAlertModal(false);

            if(engine.current.alertModalCallback !== false){

                engine.current.alertModalCallback();
                engine.current.alertModalCallback = false;
            }
        },

        // Handle confirm modal

        openConfirmModal: (message, callback, button) => {

            setConfirmModal({message: message, ok: button});

            if(callback !== undefined){

                engine.current.confirmModalCallback = callback;
            }
        },

        closeConfirmModal: execute => {

            setConfirmModal(false);

            if(engine.current.confirmModalCallback !== false){

                if(execute === true){

                    engine.current.confirmModalCallback();
                }

                engine.current.confirmModalCallback = false;
            }
        },

        openForbiddenModal: () => {

            engine.current.openAlertModal(getLang('system').messages.forbidden, () => {

                engine.current.logout();
                engine.current.redirect();
            });
        },

        openNetworkErrorModal: callback => {

            if(callback !== undefined){

                const message = getLang('system').messages.network_error;
                const button =  getLang('system').confirm_modal.try_again;

                engine.current.openConfirmModal(message, callback, button);
            }

            else {

                engine.current.openAlertModal(getLang('system').messages.network_error);
            }
        },

        // Handle login

        login: (accessToken, refreshToken, groups) => {

            localStorage.setItem('access_token', accessToken);
            localStorage.setItem('refresh_token', refreshToken);
            engine.current.setState({groups: groups});

            return true;
        },

        logout: () => {

            // Clear stores groups, dependencies cache and tokens

            dispatchStores({type: 'clear'});
            engine.current.setState({groups: []});
            engine.current.cache.clear();
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');

            return true;
        },

        redirect: () => {

            engine.current.setRoute(config.redirect.forbidden(app.path));
        }
    });

    const router = useMemo(() => parseRoutes(mainRoutes, app.path), [mainRoutes, app.path]);

    // View found

    if(router !== false){

        router.current = router.routes[router.routes.length - 1]; // Set current route (helper) 

        [engine.current.side] = router.current.component.split('/'); // Set side
    }

    if(app.reset === true){

        return false;
    }

    const theme = engine.current.imports.get((engine.current.side !== undefined ? engine.current.side : config.defaultTheme) + '@theme');

    if(app.initializing === true){

        return (

            <ThemeProvider theme={theme.module}>

                <Loading />

            </ThemeProvider>
        );
    }

    // Init is done after this line!!!

    function getCurrentPath(){

        if(router === false){

            return false;
        }

        return router.routes.map(route => route.path);
    }

    function getLang(type){

        let imported;

        if(type === 'component'){

            imported = engine.current.imports.get(engine.current.lang + '@' + engine.current.side);
        }

        else if(type === 'system'){

            imported = engine.current.imports.get(engine.current.lang + '@lang');
        }

        if(imported === undefined){

            return false;
        }

        return imported.module;
    }

    const values = {

        stores: [stores, dispatchStores],

        route: {

            setRoute: engine.current.setRoute,
            route: {path: getCurrentPath(), params: router.params}
        },

        langify: [getLang('component'), getLang('system'), lang, setLang],

        alertModal: engine.current.openAlertModal,
        confirmModal: engine.current.openConfirmModal,

        loading: {

            state: engine.current.state.loading,
            setLoading: loading => engine.current.setState({loading: loading})
        },

        groups: {

            state: engine.current.state.groups,
            setGroups: groups => engine.current.setState({groups: groups})
        },

        openForbiddenModal: engine.current.openForbiddenModal,
        openNetworkErrorModal: engine.current.openNetworkErrorModal,

        authentication: {

            login: engine.current.login, 
            logout: engine.current.logout
        },

        side: engine.current.side,

        sharedState: {

            state: engine.current.state.sharedState,
            setState: state => engine.current.setState({sharedState: merge(engine.current.state.sharedState, state)})
        }
    };

    function Logger(){

        if(process.env.NODE_ENV === 'development' && router !== false){

            if(engine.current.prev.state.initializing === true || engine.current.prev.state.path !== app.path){

                if(engine.current.prev.state.initializing === false){

                    console.clear();
                }

                console.log('Route:', router.current.path);
                console.log('Component:', 'ui/src/components/' + router.current.component + '.js');

                const namespaces = [];

                engine.current.state.sharedState.forEach((v, namespace) => {

                    namespaces.push(namespace);
                });

                if(namespaces.length !== 0){

                    console.group('Namespaces:');

                    const sorted = sort(namespaces, 'asc');

                    sorted.forEach(namespace => {

                        console.log(namespace, '(' + typeof engine.current.state.sharedState.get(namespace) + ')');
                    });

                    console.groupEnd();
                }
            }
        }

        return false;
    }

    const Modals = props.modals;

    return (

        <ThemeProvider theme={theme.module}>

            <AppProvider value={values}>

                <Preloading isOpen={app.loading} />
                <Alert value={alertModal} close={engine.current.closeAlertModal} />
                {confirmModal !== false && <Confirm value={confirmModal.message} close={engine.current.closeConfirmModal} lang={{ok: confirmModal.ok}} />}

                {Modals !== undefined && <Modals />}

                {engine.current.state.loadingError === false && app.initializing === false && engine.current.isForbidden(app.path) === false && router !== false &&

                    router.routes.map(route => {

                        const imported = engine.current.imports.get(route.component);
                        const Component = imported.module;

                        // Pass props with useRoute for the next route

                        const props = {};

                        if(engine.current.props !== undefined && engine.current.props.path === route.routePath){

                            props.data = engine.current.props.data;
                        }

                        return <Component key={route.path} depth={route.depth} {...props} />
                    })
                }

                <Logger />

            </AppProvider>

        </ThemeProvider>
    );
}

export {route, group};
