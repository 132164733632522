import React from 'react';
import styled from 'styled-components';

const Label = styled.div(props => `

    padding-bottom: ${props.theme.padding.normal};
    ${props.error !== false && `color: ${props.theme.color.negative.normal}`}
`);

const StyledInput = styled.input.attrs(props => ({

    marginBottom: props.styled.marginBottom || props.theme.margin.normal,
    width: props.styled.width || '100%'
}))`

    ${props => `

        width: ${props.width};
        height: ${props.theme.height.normal};
        padding: 0 ${props.theme.padding.smaller} 0 ${props.theme.padding.smaller};
        border: 1px solid ${props.theme.borderColor.main.normal};
        border-radius: ${props.theme.borderRadius.smaller};
        margin-bottom: ${props.marginBottom};
        outline: 0;
    `}
`;

function Input(props){

    function change(e){

        const input = {

            name: props.name,
            value: e.target.value
        };

        return props.onChange(input);
    }

    return (

        <React.Fragment>

            {props.type !== 'hidden' && <Label error={props.error}>{props.label}:</Label>}

            <div>

                <StyledInput

                    styled={props.styled || {}}
                    name={props.name}
                    value={props.value}
                    onChange={change}
                    type={props.type}
                    maxLength={props.maxlength === undefined ? 524288 : props.maxlength}
                />

            </div>

        </React.Fragment>
    );
}

Input.defaultProps = {

    error: false,
    type: 'text'
}

export default Input;
