import React from 'react';
import styled from 'styled-components';
import arrow from '../images/assets/arrow-down.png';

console.log('Arrow:', arrow);

const Label = styled.div(props => `

    padding-bottom: ${props.theme.padding.normal};
    ${props.error !== false && `color: ${props.theme.color.negative.normal}`}
`);

const Input = styled.select.attrs(props => ({

    marginBottom: props.styled.marginBottom || props.theme.margin.normal,
    width: props.styled.width || '100%'
}))`

    ${props => `

        width: ${props.width};
        height: ${props.theme.height.normal};
        border-radius: ${props.theme.borderRadius.smaller};
        font-size: ${props.theme.fontSize.normal};
        padding: 0 ${props.theme.padding.normal} 0 ${props.theme.padding.normal};
        border: ${props.theme.borderWidth.smaller} solid ${props.theme.borderColor.main.normal};
        ${props.marginBottom !== undefined && `margin-bottom: ${props.marginBottom};`}
        -webkit-appearance: none;
        background-color: #fff;
        background-image: url('${arrow}');
        background-repeat: no-repeat;
        background-position: right center;
        cursor: pointer;

        &:focus {

            outline: 0;
            box-shadow: 0 0 0.3em ${props.borderColor} !important;
        }
    `}
`;

function Select(props){

    function change(e){

        const input = {

            name: props.name,
            value: e.target.value
        };

        return props.onChange(input);
    }

    return (

        <React.Fragment>

            <Label error={props.error}>{props.label}:</Label>

            <Input styled={props.styled} onChange={change} value={props.value}>

                {props.options.map((v, i) => <option value={v.value} key={i}>{v.text}</option>)} 

            </Input>

        </React.Fragment>
    );
}

Select.defaultProps = {

    error: false,
    styled: {}
}

export default Select;
